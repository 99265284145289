/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Input, Modal } from "rsuite";

import "../../components/HistoryOrder/styles.scss";

const FormOrder = (props: any) => {
  const { type, data, handleClose, reservation_types } = props;
  const [state, setState] = useState<any>({
    id: "",
    name: "",
    terminal: "",
    company: "",
    code: "",
    state: "",
    external_reference: "",
    delivered_at: "",
  });

  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (type !== "create") {
      setState({ ...state, ...data });
    }
  }, []);

  useEffect(() => {
    const reservation: any = data?.reservations[data?.reservations?.length - 1];
    let items: any;
    if (reservation.reservation_type === "pickup_box_created") {
      items = data?.packages;
    } else {
      items = data?.packages?.filter((item: any) =>
        reservation?.boxes?.some((box: any) => box.id === item.box_id)
      );
    }
    setItems([...items]);
  }, [data?.packages]);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = (e: any) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Numero Orden</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">#{state.id}</div>
            ) : (
              <Input
                placeholder="Numero Orden"
                value={state.id}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "id", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.delivery_user?.name}
              </div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Email</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">
                {state.delivery_user?.email}
              </div>
            ) : (
              <Input
                placeholder="Email"
                value={state.email}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "email", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Telefono</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">
                {state.delivery_user?.phone}
              </div>
            ) : (
              <Input
                placeholder="Email"
                value={state.phone}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "phone", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Terminal</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.terminal?.username}
              </div>
            ) : (
              <Input
                placeholder="Terminal"
                value={state.terminal}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "terminal", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Empresa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company?.name}
              </div>
            ) : (
              <Input
                placeholder="Empresa"
                value={state.company}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "company", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Código</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.code}</div>
            ) : (
              <Input
                placeholder="Código"
                value={state.code}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "code", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Estado</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.state}</div>
            ) : (
              <Input
                placeholder="Estado"
                value={state.state}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "state", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Fecha delivery</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.delivered_at
                  ? moment(state.delivered_at).format("DD/MM/YYYY H:mm:ss")
                  : "Aguardando..."}
              </div>
            ) : (
              <Input
                placeholder="Fecha inicio"
                value={state.delivered_at}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "delivered_at", value } })
                }
              />
            )}
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Fecha retiro</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.withdrawn_at
                  ? moment(state.withdrawn_at).format("DD/MM/YYYY H:mm:ss")
                  : "Aguardando..."}
              </div>
            ) : (
              <Input
                placeholder="Fecha inicio"
                value={state.delivered_at}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "delivered_at", value } })
                }
              />
            )}
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Referencia Externa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.external_reference}
              </div>
            ) : (
              <Input
                placeholder="Referencia externa"
                value={state.external_reference}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "external_reference", value },
                  })
                }
              />
            )}
          </div>
        </div>

        <div className="row px-3 mb-3 mx-2 mt-5 size-08 border-bottom bold">
          Items
        </div>

        <div className="row px-3 mb-3 mx-2 size-08 border-bottom bold-300">
          <div className="col-10">Nombre</div>
          <div className="col-2 text-center">Caja</div>
        </div>
        {items.map((item: any) => (
          <div className="row px-3 mb-3 mx-2 size-08 border-bottom">
            <div className="col-10 capitalize">{item.name}</div>
            <div className="col-2 text-center">{item.box?.door_number}</div>
          </div>
        ))}

        <div className="row border-bottom px-3 mb-3 mx-2 mt-5 size-08 bold">
          Histórico
        </div>
        <div className="row history-component">
          {data?.reservations?.map((item: any) => (
            <div
              className="col-12 history-component__history-item"
              key={item.id}
            >
              <span>
                {
                  reservation_types.find(
                    (rt: any) => rt.value === item.reservation_type
                  )?.label
                }
              </span>
              <div>
                <span className="bold-300">Fecha creacion: </span>
                <span>
                  {" "}
                  {moment(item.created_at).format("DD/MM/YYYY H:mm:ss")}
                </span>
              </div>
              <div>
                <span className="bold-300">Inicio reserva: </span>
                <span>
                  {" "}
                  {moment(item.start_at).format("DD/MM/YYYY H:mm:ss")}
                </span>
              </div>
              <div>
                <span className="bold-300">Termino reserva: </span>
                <span> {moment(item.end_at).format("DD/MM/YYYY H:mm:ss")}</span>
              </div>
              <div>
                <span className="bold-300">Caja(s): </span>
                <span>
                  {`${item.boxes
                    ?.map((box: any) => box.door_number)
                    ?.join(" , ")}`}{" "}
                </span>
              </div>
              <div>
                <span className="bold-300">Código: </span>
                <span> {item.code}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormOrder;
