import api from "./api";
import store from "../redux/store";

class CompanyService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/companies${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  listByUser = async () => {
    try {
      const response = await api.get(`/companies/list/list-by-user`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectList = async () => {
    try {
      const response = await api.get(`/companies/list/select-list`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/companies", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response = await api.put(`/companies/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/companies/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/companies/delete-list`, {
        list: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  asociateTerminals = async (payload: any) => {
    try {
      const response: any = await api.post(`/companies/terminals/asociate`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getTerminals = async (payload:any) => {
    try {
      const response: any = await api.get(`/companies/terminals/list/${payload}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default CompanyService;
