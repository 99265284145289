import ParagraphIcon from "@rsuite/icons/Paragraph";
import EmailIcon from '@rsuite/icons/Email';
import MessageIcon from '@rsuite/icons/Message';
import HistoryIcon from '@rsuite/icons/History';

export const _actionMenuSpeaker = (data: any, _handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Detalles",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Historico",
        Icon: HistoryIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Reenviar email",
        Icon: EmailIcon,
        show: data?.state?.toLowerCase() !== 'cancelado' && data?.state?.toLowerCase() !== 'retirado',
        eventKey: 3,
      },
      {
        label: "Reenviar sms",
        Icon: MessageIcon,
        show: data?.state?.toLowerCase() !== 'cancelado' && data?.state?.toLowerCase() !== 'retirado',
        eventKey: 4,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, id: data.id }),
  };
};

export const _actionMenuSpeakerAll = (_handleOnSelect: any) => {
  return {
    menu: [
      {
        label: "Exportar",
        Icon: ParagraphIcon,
        show: true,
        eventKey: 1,
      },
      {
        label: "Reenviar email",
        Icon: EmailIcon,
        show: true,
        eventKey: 2,
      },
      {
        label: "Reenviar sms",
        Icon: MessageIcon,
        show: true,
        eventKey: 3,
      },
    ],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey }),
  };
};
