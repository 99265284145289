export const states:any = [
    {
        value:'orden iniciada',
        label: 'Orden Iniciada'
    },
    {
        value:'en el locker',
        label: 'En el Locker'
    },
    {
        value:'Retirado',
        label: 'Retirado'
    },
    {
        value:'cancelado',
        label: 'Cancelado'
    }
]