import api from "./api";
import store from "../redux/store";

class UserService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/users${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  search = async (value: any) => {
    try {
      const response = await api.post("/users/search", { search: value });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  filter = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/user/get/filters/list${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/users", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  updatePassword = async (payload: any) => {
    try {
      const response = await api.put(`/users/update/password/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response = await api.put(`/users/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/users/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/users/delete-list`, {
        list: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createOrUpdateMasterKey = async (payload: any) => {
    try {
      const response: any = await api.post(`/users/master-key`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createOrUpdateExternalKey = async (payload: any) => {
    try {
      const response: any = await api.post(`/users/external-key`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default UserService;
