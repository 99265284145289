import { Button, Input, InputGroup, SelectPicker, Toggle } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { TiInfoOutline } from "react-icons/ti";

const Box = (props: any) => {
  const { _handleOnChange, _handleDelete, box_types, models, state, id } = props;
  return (
    <div className="row border-bottom">
      <div className="col-12 col-sm-4 mb-2">
        <label className="size-08 bold-300 ms-1">Modelo</label>
        <InputGroup inside>
          <SelectPicker
            className={`${
              state.validations &&
              Object.keys(state.validations).find(
                (key: any) => key === "model_type"
              )
                ? "border-3 border-bottom border-danger w-100 rounded"
                : "w-100"
            }`}
            locale={{
              placeholder: "Selecciona un tipo",
              searchPlaceholder: "Buscar",
              emptyMessage: "Ningun tipo encontrado",
              noResultsText: "Ningun tipo encontrad",
            }}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "model_type", value, id } })
            }
            placement="auto"
            value={state.model_type}
            data={models}
          />
          {state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "model_type"
            ) && (
              <InputGroup.Addon>
                <TiInfoOutline style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
        </InputGroup>
      </div>

      <div className="col-12 col-sm-4 mb-2">
        <label className="size-08 bold-300 ms-1">Hexadecimal</label>
        <InputGroup inside>
          <Input
            placeholder=""
            value={state.hexadecimal}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "hexadecimal", value, id } })
            }
          />
          {state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "hexadecimal"
            ) && (
              <InputGroup.Addon>
                <TiInfoOutline style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
        </InputGroup>
      </div>

      <div className="col-12 col-sm-3 mb-2">
        <label className="size-08 bold-300 ms-1">Puerta</label>
        <InputGroup inside>
          <Input
            placeholder=""
            value={state.door_number}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "door_number", value, id } })
            }
          />
          {state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "door_number"
            ) && (
              <InputGroup.Addon>
                <TiInfoOutline style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
        </InputGroup>
      </div>
      <div className="col-12 col-sm-3 mb-2">
        <label className="size-08 bold-300 ms-1">Puerta Terminal</label>
        <InputGroup inside>
          <Input
            placeholder=""
            value={state.desk_door_number}
            onChange={(value: any) =>
              _handleOnChange({
                target: { name: "desk_door_number", value, id },
              })
            }
          />
          {state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "desk_door_number"
            ) && (
              <InputGroup.Addon>
                <TiInfoOutline style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
        </InputGroup>
      </div>
      <div className="col-12 col-sm-3 mb-2">
        <label className="size-08 bold-300 ms-1">Terminal | Sidelocker</label>
        <InputGroup inside>
          <Input
            placeholder=""
            value={state.desk_number}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "desk_number", value, id } })
            }
          />
          {state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "desk_number"
            ) && (
              <InputGroup.Addon>
                <TiInfoOutline style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
        </InputGroup>
      </div>
      <div className="col-12 col-sm-3 mb-2">
        <label className="size-08 bold-300 ms-1">Tipo de caja</label>
        <SelectPicker
          className={`${
            state.validations &&
            Object.keys(state.validations).find(
              (key: any) => key === "box_type_id"
            )
              ? "border-3 border-bottom border-danger w-100 rounded"
              : "w-100"
          }`}
          locale={{
            placeholder: "Selecciona un tipo",
            searchPlaceholder: "Buscar",
            emptyMessage: "Ningun tipo encontrado",
            noResultsText: "Ningun tipo encontrad",
          }}
          onChange={(value: any) =>
            _handleOnChange({ target: { name: "box_type_id", value, id } })
          }
          placement="auto"
          value={state.box_type_id}
          data={box_types}
        />
      </div>

      <div className="col-12 col-sm-1 mb-2 d-flex flex-column align-items-center justify-content-around">
        <label className="size-08 bold-300 ms-1">
          {state.is_enabled ? "Deshabilitar" : "Habilitar"}
        </label>
        <Toggle
          checked={state.is_enabled}
          onChange={(value: any) =>
            _handleOnChange({ target: { name: "is_enabled", value, id } })
          }
        />
      </div>
      <div className="col-12 col-sm-1 mb-2 d-flex align-items-end justify-content-center">
        <Button
          onClick={() => _handleDelete(id)}
          appearance="subtle"
          className="bold-300 p-0 m-0"
        >
          <TrashIcon className="mb-1" style={{ fontSize: "1.5rem" }} />
        </Button>
      </div>
    </div>
  );
};

export default Box;
