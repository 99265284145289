/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, SelectPicker, Toggle } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import UploadFile from "../../components/UploadFiles";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import MailerService from "../../services/mailer.service";
import { MailerSchema } from "../../validations/mailer.validations";
import { templates } from "./templates";
import TokenConection from "./TokenConnection";
import PasswordConnection from "./PasswordConnection";

const FormMailer = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    primary_color: "#000000",
    secondary_color: "#FFE700",
    third_color: "#FFFFFF",
    fourth_color: "#FFFFFF",
    is_provider: false,
    validations: {},
    set_colors: false,
    is_password_connection: false,
    create_token_url: '',
    token_path: '',
    header: '',
    fields: ''
  });

  const Company = new CompanyService();
  const Mailer = new MailerService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type !== "create") {
      _getData(data);
    } else {
      _getData();
    }
  }, []);

  const _getData = async (data: any = null) => {
    try {
      dispatch(loading_on());
      const responseGetCompanies: any = await Company.selectList();
      if (data) {
        const template: any = templates.find(
          (item: any) => item.value === data.template_name
        );
        if (template) {
          state.set_colors = template.colors;
        }
        setState({ ...state, ...data, companies: responseGetCompanies.data });
      } else {
        setState({ ...state, companies: responseGetCompanies.data });
      }

      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, "No fue posible cargar las empresas");
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;

    if (name === "template_name") {
      const template: any = templates.find((item: any) => item.value === value);
      if (template) {
        state.set_colors = template.colors;
      }
    }
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(MailerSchema, state);

    setState({ ...state, validations });
    if (validations) {
      return;
    }

    let payload: any = {}

    try {
      payload = {
        company_id: state.company_id,
        template_name: state.template_name,
        primary_color: state.primary_color,
        secondary_color: state.secondary_color,
        third_color: state.third_color,
        fourth_color: state.fourth_color,
        is_provider: state.is_provider,
        provider_url: state.provider_url || "",
        is_password_connection: state.is_password_connection,
        create_token_url: state.create_token_url,
        token_path: state.token_path,
        header: JSON.parse(state.header),
        fields: JSON.parse(state.fields),
        logo: state.logo?.base64 || "",
        secret: state.secret || "",
      };
    } catch (e: any) {
      _handleError({}, "Verifica los datos ingresados ​​en Header y Usuario y contraseña");
      return;
    }

    if (!payload.is_provider) {
      payload.is_password_connection = false;
      payload.create_token_url = '';
      payload.token_path = '';
    }

    dispatch(loading_on());

    try {
      if (type === "create") {
        await Mailer.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        payload.mailer_template_id = state.mailer_template_id;
        await Mailer.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Mailer ${type === "create" ? "creado" : "actualizado"
          } con éxito...`,
      });

      getData();
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Empresa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company_name}
              </div>
            ) : (
              <SelectPicker
                data={state.companies || []}
                value={state.company_id}
                className="w-100"
                placeholder="Selecciona una empresa"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "company_id", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="company_id"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1 w-100">Envio Externo</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.is_provider ? "Envio Externo" : "Enviado por Elock"}
              </div>
            ) : (
              <Toggle
                checked={state.is_provider}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "is_provider", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="is_provider"
              validations={state.validations}
            />
          </div>

          {state.is_provider ? (
            <>
              <div className="col-12 mb-2">
                <label className="size-08 bold-300 ms-1">Usuario y contraseña</label>
                {type && type === "view" ? (
                  <div className="size-08 ms-2">{state.is_password_connection ? 'Sí' : 'No'}</div>
                ) : (
                  <Toggle
                    onChange={(value: any) =>
                      _handleOnChange({
                        target: { name: "is_password_connection", value },
                      })
                    }
                    checked={state.is_password_connection}
                    className="w-100"
                  />)}
              </div>

              {state.is_password_connection ?
                <PasswordConnection type={type} _handleOnChange={_handleOnChange} state={state} /> :
                <TokenConection type={type} _handleOnChange={_handleOnChange} state={state} />}
            </>
          ) : null}

          {!state.is_provider && (
            <>
              <div className="col-6 mb-2">
                <label className="size-08 bold-300 ms-1">Template</label>
                {type && type === "view" ? (
                  <div className="size-08 ms-2 capitalize">
                    {state.template_name}
                  </div>
                ) : (
                  <SelectPicker
                    data={templates || []}
                    value={state.template_name}
                    className="w-100"
                    placeholder="Selecciona un template"
                    onChange={(value: any) =>
                      _handleOnChange({
                        target: { name: "template_name", value },
                      })
                    }
                  />
                )}
                <ErrorValidation
                  className="text-end size-08"
                  name="template_name"
                  validations={state.validations}
                />
              </div>
              <div className="col-6 mb-2">
                <label className="size-08 bold-300 ms-1">Logo</label>
                {type && type === "view" ? (
                  <div className="size-08 ms-2 capitalize">
                    <a href={state.logo} target="_blank" rel="noreferrer">
                      Logo
                    </a>
                  </div>
                ) : (
                  <UploadFile
                    text="Agregar logo"
                    accept=".jpg,.png,.bmp,.jpeg"
                    onChange={(value: any) =>
                      _handleOnChange({
                        target: { name: "logo", value },
                      })
                    }
                  />
                )}
                <ErrorValidation
                  className="text-end size-08"
                  name="logo"
                  validations={state.validations}
                />
              </div>

              {state.set_colors && (
                <>
                  <div className="col-6 mb-2">
                    <label className="size-08 bold-300 ms-1">Color 1</label>
                    {type && type === "view" ? (
                      <div
                        className="size-08 ms-2 capitalize rounded"
                        style={{
                          background: state.primary_color,
                          width: "100%",
                          height: "30px",
                          border: "1px solid #000",
                        }}
                      />
                    ) : (
                      <input
                        type="color"
                        className="w-100"
                        disabled={!state.set_colors}
                        value={state.primary_color}
                        onChange={({ target }: any) =>
                          _handleOnChange({
                            target: {
                              name: "primary_color",
                              value: target.value,
                            },
                          })
                        }
                      />
                    )}
                    <ErrorValidation
                      className="text-end size-08"
                      name="primary_color"
                      validations={state.validations}
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <label className="size-08 bold-300 ms-1">Color 2</label>
                    {type && type === "view" ? (
                      <div
                        className="size-08 ms-2 capitalize rounded"
                        style={{
                          background: state.secondary_color,
                          width: "100%",
                          height: "30px",
                          border: "1px solid #000",
                        }}
                      />
                    ) : (
                      <input
                        type="color"
                        className="w-100"
                        disabled={!state.set_colors}
                        value={state.secondary_color}
                        onChange={({ target }: any) =>
                          _handleOnChange({
                            target: {
                              name: "secondary_color",
                              value: target.value,
                            },
                          })
                        }
                      />
                    )}
                    <ErrorValidation
                      className="text-end size-08"
                      name="secondary_color"
                      validations={state.validations}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <label className="size-08 bold-300 ms-1">Color 3</label>
                    {type && type === "view" ? (
                      <div
                        className="size-08 ms-2 capitalize rounded"
                        style={{
                          background: state.third_color,
                          width: "100%",
                          height: "30px",
                          border: "1px solid #000",
                        }}
                      />
                    ) : (
                      <input
                        type="color"
                        className="w-100"
                        disabled={!state.set_colors}
                        value={state.third_color}
                        onChange={({ target }: any) =>
                          _handleOnChange({
                            target: {
                              name: "third_color",
                              value: target.value,
                            },
                          })
                        }
                      />
                    )}
                    <ErrorValidation
                      className="text-end size-08"
                      name="third_color"
                      validations={state.validations}
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <label className="size-08 bold-300 ms-1">Color 4</label>
                    {type && type === "view" ? (
                      <div
                        className="size-08 ms-2 capitalize rounded"
                        style={{
                          background: state.fourth_color,
                          width: "100%",
                          height: "30px",
                          border: "1px solid #000",
                        }}
                      />
                    ) : (
                      <input
                        type="color"
                        className="w-100"
                        disabled={!state.set_colors}
                        value={state.fourth_color}
                        onChange={({ target }: any) =>
                          _handleOnChange({
                            target: {
                              name: "fourth_color",
                              value: target.value,
                            },
                          })
                        }
                      />
                    )}
                    <ErrorValidation
                      className="text-end size-08"
                      name="fourth_color"
                      validations={state.validations}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormMailer;
