/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, TagPicker, Toggle } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { set_loader } from "../../redux/actions/loader";
import TerminalService from "../../services/terminal.service";
import UserService from "../../services/user.service";

const MasterKeyForm = (props: any) => {
  const { handleClose, handleConfirm, open, data } = props;
  const Terminal = new TerminalService();
  const [state, setState] = useState<any>({
    terminals: [],
    terminalsSeted: [],
    renew: true,
  });
  const User = new UserService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (open) {
      _loadTerminals();
    }
  }, []);

  const _handleOnChange = (payload: any) => {
    const { target } = payload;
    state[target.name] = target.value;

    if (target.name === "renew" && !data?.masterkey) {
      _pushToastMessage({
        text: "Al crear la primera master key para um usuario, este campo es obligatorio.",
        type: "warning",
        header: "Master key",
      });
      state[target.name] = true;
    }
    setState({ ...state });
  };

  const _loadTerminals = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response: any = await Terminal.selectList();
      let terminalsSeted: any = [];
      let renew: any = true;
      if (data?.masterkey && data?.masterkey?.terminals.length > 0) {
        terminalsSeted = data?.masterkey?.terminals.map((item: any) => item.id);
      }

      if (data?.masterkey) {
        renew = false;
      }
      setState({ ...state, terminals: response.data, terminalsSeted, renew });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (state.terminalsSeted.length === 0) {
      _pushToastMessage({
        text: "Elija al menos un terminal para continuar.",
        type: "warning",
        header: "Master key",
      });
      return;
    }

    try {
      dispatch(set_loader({ is_loading: true }));
      const payload: any = {
        id: data?.id,
        terminals: state.terminalsSeted,
        renew: state.renew,
      };
      await User.createOrUpdateMasterKey(payload);
      handleConfirm();
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }

    console.log(state, data);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 mb-3">
          <div className="size-08">Nombre</div>
          <div className="size-09 ms-2">{data?.name}</div>
        </div>

        <div className="col-6 mb-3">
          <div className="size-08">Master Key</div>
          <div className="size-09 ms-2">{data?.masterkey?.key}</div>
        </div>

        <div className="col-6">
          <div className="size-08">Terminales</div>
          <TagPicker
            data={state.terminals}
            className="w-100"
            onChange={(e: any) =>
              _handleOnChange({ target: { value: e, name: "terminalsSeted" } })
            }
            value={state.terminalsSeted}
          />
        </div>

        <div className="col-6">
          <div className="size-08">Crear o renovar codigo</div>
          <div>
            <Toggle
              checked={state.renew}
              onChange={(e: any) =>
                _handleOnChange({ target: { value: e, name: "renew" } })
              }
            />
          </div>
        </div>
      </div>
      <form onSubmit={_handleOnSubmit}>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default MasterKeyForm;
